import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import { PAYMENT_STATUS, PAYMENT_STATUS_COLOR, PAYMENT_TYPE } from 'src/models/PaymentModel';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import moment from 'moment-timezone-all';
import ButtonLink from 'src/components/Elements/ButtonLink';
import UserDownloadPaymentButton
  from 'src/components/DatagridToolbar/buttons/UserDownloadPaymentButton';
import { CreditCardModel } from 'src/models/CreditCardModel';
import { BankAccountModel } from 'src/models/BankAccountModel';
import { setSelections } from 'src/redux/actions/selectionActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { getInvoiceAction } from 'src/redux/actions/invoiceActions';
// import { postPurchaseAction } from 'src/redux/actions/paymentActions';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: MODALS_SIZE.large,
  },
  subheader: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginTop: '12px',
    marginBottom: '4px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '32px',
  },
  column: {
    maxWidth: '640px',
  },
  col2: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  col3: {
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
  },
  nomargin: {
    marginBottom: '0px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    width: '100%',
    minWidth: 120,
    margin: theme.spacing(3, 2, 0),
  },
  control: {
    minHeight: '56px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  clickable: {
    cursor: 'pointer',
  },
  pagination: {
    margin: '8px 0',
    height: '24px',
    '& > ul': {
      justifyContent: 'center',
    },
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
  editIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
    fontSize: '12px',
  },
  listNoStyle: {
    listStyle: 'none',
    padding: '0px',
    margin: '0px',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '74px',
  },
  headerRow: {
    height: '32px',
    marginBottom: 0,
  },
  scroller: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing(2),
    height: '66vh',
    padding: '8px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  paymentType: {
    margin: '0px',
    marginLeft: '-8px',
    marginTop: '-8px',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
}));

const UserEditPaymentForm = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const getStatus = useSelector((state: Store) => state.formStatus[FORM.get_payment]);
  const putStatus = useSelector((state: Store) => state.formStatus[FORM.put_payment]);
  const processing = () => putStatus === FORM_STATUS.processing
    || getStatus === FORM_STATUS.processing;

  const payment =
    useSelector((state: Store) => state.modals[MODALS.editUserPayment].payload);

  const creditCards = useSelector(
    (state: Store) => {
      if (!!state.userCreditCards) {
        return state.userCreditCards as CreditCardModel[];
      }
      return [] as CreditCardModel[];
    },
  );

  const bankAccounts = useSelector(
    (state: Store) => {
      if (!!state.userBankAccounts) {
        return state.userBankAccounts as BankAccountModel[];
      }
      return [] as BankAccountModel[];
    },
  );

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const openEditCreditCard = () => {
    dispatch(openModal({
      modal: MODALS.editUserCreditCard,
      payload: payment.payment_info_id,
    }));
  };

  const openInvoice = (id:number) => {
    dispatch(getInvoiceAction(id));
    dispatch(setSelections({
      entity: ENTITIES.invoices,
      selections: [id],
    }));
    dispatch(openModal({ modal: MODALS.viewInvoice }));
  };

  // const handleRetry = () => {
  //   const payload = {
  //     ...payment,
  //     scheduled_date: moment().startOf('day'),
  //     is_deductible: !!Number(payment.deductible),
  //     payment_gateway_id: payment.payment_gateway_id ?? undefined,
  //     seller_id: payment.seller_id ?? undefined,
  //   };
  //   dispatch<any>(postPurchaseAction(payload, MODALS.guessPayment));
  // };

  const getCreditCard = (id: number) => {
    if (!id) return 'N/A';
    const result = creditCards.find((creditCard) => creditCard.id === id);
    if (!result) return 'N/A';
    return result.masked_card_number;
  };

  const getBankAccount = (id: number) => {
    if (!id) return 'N/A';
    const result = bankAccounts.find((bankAccount) => bankAccount.id === id);
    if (!result) return 'N/A';
    return result.account.slice(-4);
  };

  const displayPaymentInfo = () => {
    if (payment.payment_type === PAYMENT_TYPE.cc) {
      return (
        <div>
          <span className={classes.label}>Credit Card Used:</span>
          {getCreditCard(payment.payment_info_id as number)}
        </div>
      );
    }
    if (payment.payment_type === PAYMENT_TYPE.eft) {
      return (
        <div>
          <span className={classes.label}>Credit Card Used:</span>
          ********{getBankAccount(payment.payment_info_id as number)}
        </div>
      );
    }
    return (<></>);
  };

  const displayPaymentStatus = () => {
    const color = PAYMENT_STATUS_COLOR[payment.payment_status ?? PAYMENT_STATUS.paid];
    return (
      <span style={{ color }}>
        { payment.payment_status }
      </span>
    );
  };

  const displayScheduledDate = () => {
    if (payment.payment_status !== PAYMENT_STATUS.scheduled) return (<></>);
    if (!payment.scheduled_date) return (<></>);
    const date = moment(payment.scheduled_date).format('YYYY-MM-DD');
    return (
      <>
        <b>Scheduled Date:</b>&nbsp;{date}<br />
      </>
    );
  };

  const displayPaidDate = () => {
    if (!payment.paid_date) return (<></>);
    const date = moment(payment.paid_date).format('YYYY-MM-DD');
    return (
      <>
        <b>Paid Date:</b>&nbsp;{date}<br />
      </>
    );
  };

  const displayProcessedDate = () => {
    if (!payment.processed_date) return (<></>);
    const date = moment(payment.processed_date).format('YYYY-MM-DD');
    return (
      <>
        <b>Processed Date:</b>&nbsp;{date}<br />
      </>
    );
  };

  const displayUpdateCreditCard = () => {
    if (![
      PAYMENT_STATUS.declined,
      PAYMENT_STATUS.error,
      PAYMENT_STATUS.scheduled,
    ].includes(payment.payment_status)) return (<></>);
    if (payment.payment_type !== PAYMENT_TYPE.cc) return (<></>);
    return (
      <>
        <br />
        <ButtonLink onClick={() => openEditCreditCard()}>Update Credit Card</ButtonLink>
      </>
    );
  };

  const displayInvoices = () => {
    if (!payment.invoices) return (<></>);
    return payment.invoices.map((invoice: any) => (
      <ButtonLink onClick={() => openInvoice(invoice.id)}><i>{invoice.reference}</i></ButtonLink>
    ));
  };

  // const displayRefundButton = () => {
  //   if ([
  //     PAYMENT_STATUS.declined,
  //     PAYMENT_STATUS.error,
  //   ].includes(payment.payment_status)) return (<></>);
  //   if (![
  //     PAYMENT_TYPE.cc,
  //     PAYMENT_TYPE.eft,
  //   ].includes(payment.payment_type)) return (<></>);
  //
  //   return (
  //     <div style={{ width: '100%', textAlign: 'right' }}>
  //       <Button
  //         style={{ marginLeft: 'auto', marginRight: '16px' }}
  //         variant="contained"
  //         color="primary"
  //         size="small"
  //         onClick={handleRetry}
  //         disabled={processing()}
  //       >
  //         Retry
  //       </Button>
  //     </div>
  //   );
  // };

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.editUserPayment }));
  };

  return (
    <>
      <div className={classes.header}>
        <div className={`${classes.row} ${classes.headerRow}`}>
          <div className={classes.title}>
            {t(lang, 'forms.payments.edit_payment')}
            &nbsp;#{payment.id}
          </div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
            disabled={processing()}
          >
            {t(lang, 'misc.close')}
          </Button>
        </div>
        <div className={`${classes.row} ${classes.headerRow}`}>
          <UserDownloadPaymentButton
            disabled={payment.payment_status !== PAYMENT_STATUS.paid}
            paymentId={payment.id}
          />
        </div>
      </div>
      <div><b>Status: </b>{ displayPaymentStatus() }</div>
      <form className={classes.scroller} noValidate>
        <b>Title:</b>&nbsp;{payment.title}<br />
        <b>Amount:</b>&nbsp;{formatter.format(payment.amount ?? 0)}<br />
        {displayPaidDate()}
        {displayScheduledDate()}
        {displayProcessedDate()}
        <b>Deductible:</b>&nbsp;{formatter.format(payment.deductible ?? 0)}<br />
        <b>Payment Type:</b>&nbsp;{payment.payment_type ?? 0}<br />
        {displayPaymentInfo()}
        <b>Tax receipt issued:</b>&nbsp;{payment.issue_tax_receipt ? 'yes' : 'no'}<br />
        <b>Payment receipt sent:</b>&nbsp;{payment.payment_receipt_sent ? 'yes' : 'no'}<br />
        {!!payment.note && (
          <b>
            <b>Note:</b>&nbsp;{payment.payment_receipt_sent ? 'yes' : 'no'}<br />
          </b>
        )}
        {displayUpdateCreditCard()}
        <br />
        <b>Invoices:</b><br />
        {displayInvoices()}
        {/* {displayRefundButton()} */}
      </form>
    </>
  );
};

export default UserEditPaymentForm;
