import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { useAppDispatch } from 'src/redux/Store';
import { MODALS } from 'src/models/ModalModel';
import { openModal } from 'src/redux/actions/modalActions';
import PaymentModel from 'src/models/PaymentModel';

interface OwnProps {
  payment: PaymentModel,
}

type Props = OwnProps & ButtonProps;

const ViewUserPaymentButton = (props: Props) => {
  const { disabled, payment } = props;
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    dispatch(openModal({
      modal: MODALS.editUserPayment,
      payload: payment,
    }));
  };

  return (
    <Tooltip
      title="View detail"
      placement="top-start"
    >
      <span>
        <ActionButton
          variant="outlined"
          category={MENU_BUTTON_CATEGORY.action}
          onClick={handleOpen}
          disabled={disabled}
        >
          <VisibilityIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default ViewUserPaymentButton;
