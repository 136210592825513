import { useSelector } from 'react-redux';
import { MouseEvent, useEffect, useState } from 'react';
import { Store, useAppDispatch } from 'src/redux/Store';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  FormControl, FormControlLabel, Radio, RadioGroup, Tooltip,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import {
  capitalize, ceil, isEmpty, keyBy,
} from 'lodash';
import PaymentValidator from 'src/validations/PaymentValidator';

import {
  InstallmentModel,
  InvoicePaymentItem,
  PAYMENT_ACTION_TYPE,
  PAYMENT_FREQUENCY,
  PAYMENT_FREQUENCY_LIST,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  PaymentModel,
} from 'src/models/PaymentModel';
import { ErrorBag } from 'src/models/ErrorModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import { ProcessingType } from 'src/models/GatewayModel';
import { SelectionPayload } from 'src/models/SelectionModel';
import { InvoiceModel } from 'src/models/InvoiceModel';

import { ALERT_TYPE } from 'src/constants/AlertType';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { INVOICE_FIELDS } from 'src/constants/Invoices';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';

import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { setError } from 'src/redux/actions/errorsActions';
import { setCurrentEntity } from 'src/redux/actions/currentEntityActions';
import { postPaymentAction } from 'src/redux/actions/paymentActions';
import { getGatewayListAction } from 'src/redux/actions/gatewayAction';
import { setAlert } from 'src/redux/actions/alertActions';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import ContactSelector from 'src/components/Control/ContactSelector';
import PaymentAccountSelector from 'src/components/Control/PaymentAccountSelector';
import OrganisationBankAccountSelector
  from 'src/components/Control/OrganisationBankAccountSelector';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import ButtonLink from 'src/components/Elements/ButtonLink';
import FormSingleSelect from 'src/components/Control/FormControls/FormSingleSelect';
import Subtitle from 'src/components/Elements/Subtitle';
import MetadataSelector, { MetadataType } from 'src/components/Control/MetadataSelector';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import GatewaySelector from 'src/components/Control/GatewaySelector';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { Moment } from 'moment';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: MODALS_SIZE.large,
  },
  subheader: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginTop: '12px',
    marginBottom: '4px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  selectDate: {
    width: '100%',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  col3: {
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
  },
  clickable: {
    cursor: 'pointer',
  },
  pagination: {
    '& > ul': {
      justifyContent: 'center',
    },
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
  listNoStyle: {
    listStyle: 'none',
    padding: '0px',
    margin: '0px',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '74px',
  },
  noMarginHeight: {
    height: '32px',
    marginBottom: '0 !important',
  },
  noMargin: {
    marginBottom: '0px !important',
  },
  scroller: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing(2),
    height: '66vh',
    padding: '8px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  paymentType: {
    margin: '0px',
    marginLeft: '-8px',
    marginTop: '-8px',
  },
  installment: {
    fontStyle: 'italic',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '32px',
  },
  col2: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const MakePaymentForm = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const modalPayload =
    useSelector((state: Store) => {
      if (state.modals[MODALS.makePayment]) {
        return state.modals[MODALS.makePayment].payload as SelectionPayload;
      }
      return null;
    });

  const processing = useSelector(
    (state: Store) => state.formStatus[FORM.make_payment] === FORM_STATUS.processing,
  );
  const bankAccounts = useSelector((state: Store) => state.organisationBankAccounts);
  const organisationId = useSelector((state: Store) => state.currentOrganisation.id);
  const selections = useSelector(
    (state: Store) => modalPayload || state.selected[ENTITIES.invoices] || [],
  );

  const invoices = useSelector(
    (state: Store) => selections.map(
      (id: number) => state.invoices[id],
    ) || [] as InvoiceModel[],
  );

  const gateways = useSelector(
    (state: Store) => state.gateways,
  );

  const getInvoicesBalance = () => {
    if (isEmpty(invoices)) return 0;
    const balance = invoices.reduce(
      (total:number, invoice:InvoiceModel) => {
        if (!invoice) {
          return total;
        }
        return total + Number(invoice?.balance);
      },
      0,
    );
    const scheduled = invoices.reduce(
      (total:number, invoice:InvoiceModel) => {
        if (!invoice) {
          return total;
        }
        // @ts-ignore
        return total + Number(invoice?.scheduled);
      },
      0,
    );

    return balance - scheduled;
  };

  const getInvoicesDeductible = () => invoices.reduce(
    (total:number, invoice:InvoiceModel) => {
      if (!invoice) {
        return total;
      }
      // @ts-ignore
      return total + Number(invoice?.deductible);
    },
    0,
  );

  const issueTaxReceipt = () => {
    if (isEmpty(invoices)) return false;
    let result = false;
    invoices.forEach((invoice:InvoiceModel) => {
      result = result || !!invoice.issue_tax_receipt;
    });
    return result;
  };

  const getInitialTitle = () => {
    if (invoices[0] === undefined) {
      return '';
    }

    if (invoices.length === 1) {
      return invoices[0].title;
    }

    return invoices.map((invoice: InvoiceModel) => invoice.invoice_number).join('/');
  };

  const [paymentState, setPaymentState] = useState({
    payment_type: PAYMENT_TYPE.cc,
    payment_action: PAYMENT_ACTION_TYPE.credit,
    payment_status: PAYMENT_STATUS.scheduled,
    scheduled_date: moment().startOf('day'),
    is_installment: false,
    title: getInitialTitle(),
    contact_id: (invoices[0] === undefined) ? null : invoices[0].contact_id,
    seller_id: (invoices[0] === undefined) ? null : invoices[0].seller_id,
    amount: getInvoicesBalance(),
    deductible: getInvoicesDeductible(),
    bank_account_id: (invoices[0] === undefined) ? null : invoices[0].bank_account_id,
    account_id: (invoices[0] === undefined) ? null : invoices[0].account_id,
    program_id: (invoices[0] === undefined) ? null : invoices[0].program_id,
    department_id: (invoices[0] === undefined) ? null : invoices[0].department_id,
    issue_tax_receipt: issueTaxReceipt(),
    paid_date: undefined,
    start_date: undefined,
    processed_date: undefined,
    payment_gateway_cc_id: invoices[0].payment_gateway_cc_id,
    payment_gateway_eft_id: invoices[0].payment_gateway_eft_id,
  } as PaymentModel);

  const [installmentState, setInstallmentState] = useState({
    number: 1,
    start_date: moment().startOf('day'),
    frequency: PAYMENT_FREQUENCY.monthly,
  } as InstallmentModel);
  const [gatewayCcBankAccountId, setGatewayCcBankAccountId] = useState(0);
  const [gatewayEftBankAccountId, setGatewayEftBankAccountId] = useState(0);

  useEffect(() => {
    dispatch(setError({ [FORM.make_payment]: {} }));
    dispatch(getGatewayListAction());
  }, []);

  useEffect(() => {
    if (!!paymentState.contact_id) {
      dispatch(setCurrentEntity({ entity: ENTITIES.contacts, id: paymentState.contact_id }));
    }
  }, [paymentState.contact_id]);

  const bankAccountExists = (id: number) => {
    const accounts = bankAccounts[organisationId] ?? [];
    const result = accounts.filter((account: any) => account.id === id);
    return result.length > 0;
  };

  const isOnlinePayment =
    () => [PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(paymentState.payment_type);

  const isOfflinePayment =
  () => ![PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(paymentState.payment_type);

  const calculateInvoiceAmounts = () => {
    let remainingBalance = Number(paymentState.amount) ?? 0;
    const invoiceItems:InvoicePaymentItem[] = [];

    if (invoices.length === 1) {
      invoiceItems.push({
        invoice_id: selections[0],
        amount: remainingBalance,
      } as InvoicePaymentItem);
      return invoiceItems;
    }

    invoices.forEach((invoice: InvoiceModel) => {
      const balance = Number(invoice.balance);
      if (remainingBalance) {
        invoiceItems.push({
          invoice_id: invoice.id,
          amount: ((balance ?? 0) < remainingBalance)
            ? Number(invoice.balance)
            : remainingBalance,
        } as InvoicePaymentItem);

        remainingBalance = ((balance ?? 0) < remainingBalance)
          ? remainingBalance - (balance ?? 0)
          : 0;
      }
    });

    return invoiceItems;
  };

  const getDate = (value?: Nullable<Moment>) => {
    if (!value) return null;
    return moment(value);
  };

  const getPaymentTypesLabels = () => (
    <>
      <div className={classes.row} style={{ width: '100%', margin: '0px' }}>
        <div className={classes.col3}>
          <FormControlLabel
            className={classes.paymentType}
            value={PAYMENT_TYPE.cc}
            control={<Radio />}
            label={PAYMENT_TYPE.cc}
            key="payment-type-7"
          />
        </div>
        <div className={classes.col3}>
          <FormControlLabel
            className={classes.paymentType}
            value={PAYMENT_TYPE.eft}
            control={<Radio />}
            label={PAYMENT_TYPE.eft}
            key="payment-type-8"
          />
        </div>
        <div className={classes.col3}>
          <FormControlLabel
            className={classes.paymentType}
            value={PAYMENT_TYPE.cash}
            control={<Radio />}
            label={PAYMENT_TYPE.cash}
            key="payment-type-0"
          />
        </div>
      </div>
      <div className={classes.row} style={{ width: '100%', margin: '0px' }}>
        <div className={classes.col3}>
          <FormControlLabel
            className={classes.paymentType}
            value={PAYMENT_TYPE.check}
            control={<Radio />}
            label={PAYMENT_TYPE.check}
            key="payment-type-1"
          />
        </div>
        <div className={classes.col3}>
          <FormControlLabel
            className={classes.paymentType}
            value={PAYMENT_TYPE.inkind}
            control={<Radio />}
            label={PAYMENT_TYPE.inkind}
            key="payment-type-2"
          />
        </div>
        <div className={classes.col3}>
          <FormControlLabel
            className={classes.paymentType}
            value={PAYMENT_TYPE.interac}
            control={<Radio />}
            label={PAYMENT_TYPE.interac}
            key="payment-type-3"
          />
        </div>
      </div>
      <div className={classes.row} style={{ width: '100%', margin: '0px' }}>
        <div className={classes.col3}>
          <FormControlLabel
            className={classes.paymentType}
            value={PAYMENT_TYPE.terminal}
            control={<Radio />}
            label={PAYMENT_TYPE.terminal}
            key="payment-type-4"
          />
        </div>
        <div className={classes.col3}>
          <FormControlLabel
            className={classes.paymentType}
            value={PAYMENT_TYPE.userbalance}
            control={<Radio />}
            label={PAYMENT_TYPE.userbalance}
            key="payment-type-5"
          />
        </div>
        <div className={classes.col3}>
          <FormControlLabel
            className={classes.paymentType}
            value={PAYMENT_TYPE.wired}
            control={<Radio />}
            label={PAYMENT_TYPE.wired}
            key="payment-type-6"
          />
        </div>
      </div>
    </>
  );

  const getOnlinePaymentStatus = () => (
    [
      {
        display: PAYMENT_STATUS.scheduled,
        id: PAYMENT_STATUS.scheduled,
      },
    ]
  );

  const getOfflinePaymentStatus = () => (
    [
      {
        display: PAYMENT_STATUS.paid,
        id: PAYMENT_STATUS.paid,
      },
      {
        display: PAYMENT_STATUS.scheduled,
        id: PAYMENT_STATUS.scheduled,
      },
    ]
  );

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.makePayment }));
  };

  const openAddCreditCardModal = () => {
    dispatch(openModal({
      modal: MODALS.addCreditCard,
      payload: {
        contact_id: paymentState.contact_id,
      },
    }));
  };

  const openAddBankAccountModal = () => {
    dispatch(openModal({
      modal: MODALS.addBankAccount,
      payload: {
        contact_id: paymentState.contact_id,
      },
    }));
  };

  const onInstallmentChange = (value: Value, field: string) => {
    setInstallmentState({
      ...installmentState,
      [field]: value,
    });
  };

  const onAmountChange = (value: Value, field: string) => {
    if (!value) {
      setPaymentState({
        ...paymentState,
        amount: value as string,
      });
      return;
    }
    const stringValue = value as string || '';
    if (!stringValue.match(/^[0-9.]+$/)) return;
    const splittedString = stringValue.split('.');
    if (splittedString.length > 2) return;
    if (splittedString.length === 2) {
      if (splittedString[1].length > 2) return;
    }
    setPaymentState({
      ...paymentState,
      [field]: value as string,
    });
  };

  const onFieldChange = (value: Value, field: string) => {
    setPaymentState({
      ...paymentState,
      [field]: value,
    });
  };

  const onPaymentTypeChange = (value: PAYMENT_TYPE) => {
    if (isOnlinePayment()) {
      setPaymentState({
        ...paymentState,
        bank_account_id: (value === PAYMENT_TYPE.cc)
          ? gatewayCcBankAccountId
          : gatewayEftBankAccountId,
        payment_type: value,
        payment_status: PAYMENT_STATUS.scheduled,
      });
      return;
    }

    setPaymentState({
      ...paymentState,
      payment_type: value,
    });
  };

  const onGatewayChange = (value: number) => {
    const selectedGateway = keyBy(gateways, 'id')[value];
    if (selectedGateway.bank_account_id) {
      if (paymentState.payment_type === PAYMENT_TYPE.cc) {
        setGatewayCcBankAccountId(selectedGateway.bank_account_id);
      } else {
        setGatewayEftBankAccountId(selectedGateway.bank_account_id);
      }

      setPaymentState({
        ...paymentState,
        payment_gateway_id: value,
        bank_account_id: selectedGateway.bank_account_id,
      });
    }
  };

  const displayPaymentStatusSelector = () => {
    if (isOfflinePayment()) {
      return (
        <FormSingleSelect
          form={FORM.make_payment}
          name="paymentStatus"
          label={t(lang, 'forms.invoices.payment_status')}
          onChange={(status: string) => onFieldChange(status, 'payment_status')}
          value={paymentState.payment_status}
          items={getOfflinePaymentStatus()}
          disabled={processing}
          noMarginTop
        />
      );
    }
    return (
      <FormSingleSelect
        form={FORM.make_payment}
        name="paymentStatus"
        label={t(lang, 'forms.invoices.payment_status')}
        onChange={(status: string) => onFieldChange(status, 'payment_status')}
        value={paymentState.payment_status || PAYMENT_STATUS.scheduled}
        items={getOnlinePaymentStatus()}
        disabled
        noMarginTop
      />
    );
  };

  const displayInstallmentFrequencyOptions = () => (
    PAYMENT_FREQUENCY_LIST.map((frequency:PAYMENT_FREQUENCY, index:number) => (
      <MenuItem value={frequency} key={`payment-frequency-${index}`}>{frequency}</MenuItem>
    ))
  );

  const displayInstallmentDetails = () => {
    const step = (Number(paymentState.amount) ?? 0) / (installmentState.number ?? 1);

    if (!Number.isNaN(step) && !!installmentState.number) {
      return (
        <span className={classes.installment}>
          {installmentState.number} {capitalize(installmentState.frequency)}&nbsp;
          {t(lang, 'forms.payments.payments_of')}&nbsp;
          {step.toFixed(2)}$
        </span>
      );
    }
    return <></>;
  };

  const displayGatewaySelector = () => {
    switch (paymentState.payment_type) {
      case PAYMENT_TYPE.cc:
        return (
          <GatewaySelector
            form={FORM.make_payment}
            name="payment_gateway_id"
            onChange={onGatewayChange}
            type={ProcessingType.cc}
            value={paymentState.payment_gateway_id}
            noMarginTop
          />
        );
      case PAYMENT_TYPE.eft:
        return (
          <GatewaySelector
            form={FORM.make_payment}
            name="payment_gateway_id"
            onChange={onGatewayChange}
            type={ProcessingType.eft}
            value={paymentState.payment_gateway_id}
            noMarginTop
          />
        );
      default:
        return (<></>);
    }
  };

  const displayPaymentInfoSelector = () => {
    if (paymentState.payment_type === PAYMENT_TYPE.cc) {
      return (
        <ButtonLink onClick={() => openAddCreditCardModal()}>{t(lang, 'forms.payments.add_credit_card')}</ButtonLink>
      );
    }
    if (paymentState.payment_type === PAYMENT_TYPE.eft) {
      return (
        <ButtonLink onClick={() => openAddBankAccountModal()}>{t(lang, 'forms.payments.add_bank_account')}</ButtonLink>
      );
    }
    return (<></>);
  };

  const displaySaveIcon = () => {
    if (processing) {
      return (
        <div style={{ padding: '2px', height: '24px' }}>
          <CircularProgress color="inherit" size="20px" />
        </div>
      );
    }
    return (<AttachMoney />);
  };

  const displayProcessedDate = () => {
    if (isOnlinePayment()) return (<></>);
    if (paymentState.payment_status !== PAYMENT_STATUS.paid) return (<></>);
    if (paymentState.is_installment) return (<></>);
    return (
      <div className={`${classes.row} ${classes.noMargin}`}>
        <div className={classes.col2}>
          <FormDatePicker
            form={FORM.make_payment}
            required
            label={t(lang, 'forms.payments.processed_date')}
            name="processed_date"
            onChange={
              (value: MaterialUiPickersDate) => onFieldChange(
                value,
                'processed_date',
              )
            }
            value={getDate(paymentState.processed_date)}
          />
        </div>
        <div className={classes.col2}>&nbsp;</div>
      </div>
    );
  };

  const displayDate = () => {
    if (paymentState.is_installment) {
      return (
        <FormDatePicker
          form={FORM.make_payment}
          required
          label={t(lang, 'forms.payments.start_date')}
          name="start_date"
          onChange={
            (value: MaterialUiPickersDate) => onInstallmentChange(
              value,
              'start_date',
            )
          }
          value={getDate(installmentState.start_date)}
        />
      );
    }

    if (isOnlinePayment()) {
      return (
        <FormDatePicker
          form={FORM.make_payment}
          required
          label={t(lang, 'forms.payments.paid_date')}
          name="scheduled_date"
          onChange={
            (value: MaterialUiPickersDate) => onFieldChange(
              value,
              'scheduled_date',
            )
          }
          value={getDate(paymentState.scheduled_date)}
          disabled={processing}
        />
      );
    }
    if (isOfflinePayment()) {
      return (
        <FormDatePicker
          form={FORM.make_payment}
          required
          label={t(lang, 'forms.payments.paid_date')}
          name="paid_date"
          onChange={
            (value: MaterialUiPickersDate) => onFieldChange(
              value,
              'paid_date',
            )
          }
          value={getDate(paymentState.paid_date)}
          disabled={processing}
        />
      );
    }

    return <></>;
  };

  const getBankAccountId = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { bank_account_id } = paymentState;
    if (bank_account_id && !bankAccountExists(bank_account_id)) {
      return undefined;
    }
    return bank_account_id;
  };

  const getPaymentInfoId = () => (isOnlinePayment() ? paymentState.payment_info_id : undefined);

  const getProcessedDate = () => {
    if (isOnlinePayment()) return undefined;
    if (paymentState.payment_status !== PAYMENT_STATUS.paid) return undefined;
    if (paymentState.is_installment) return undefined;
    return paymentState.processed_date;
  };

  const getDates = () => {
    if (paymentState.is_installment) {
      return {
        paid_date: undefined,
        scheduled_date: undefined,
        start_date: paymentState.start_date,
        processed_date: getProcessedDate(),
      };
    }
    if (paymentState.payment_status === PAYMENT_STATUS.paid) {
      return {
        paid_date: paymentState.paid_date,
        scheduled_date: undefined,
        start_date: undefined,
        processed_date: getProcessedDate(),
      };
    }
    return {
      paid_date: undefined,
      scheduled_date: paymentState.scheduled_date,
      start_date: undefined,
      processed_date: getProcessedDate(),
    };
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>, close:boolean = false) => {
    event.preventDefault();
    dispatch(setError({ [FORM.make_payment]: {} }));
    const data = {
      ...paymentState,
    };
    try {
      PaymentValidator(lang).validateSync({ ...data }, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.make_payment]: errorBag }));
      return false;
    }

    // INSTALLMENT
    if (paymentState.is_installment) {
      const payload = {
        ...paymentState,
        paid_date: getDates().paid_date,
        scheduled_date: getDates().scheduled_date,
        start_date: getDates().start_date,
        processed_date: getDates().processed_date,
        bank_account_id: getBankAccountId(),
        payment_info_id: getPaymentInfoId(),
        invoices: calculateInvoiceAmounts(),
        installment: installmentState,
      };
      dispatch<any>(postPaymentAction(payload, close));
      return true;
    }

    // ONLINE
    if (isOnlinePayment()) {
      const payload = {
        ...paymentState,
        paid_date: getDates().paid_date,
        scheduled_date: getDates().scheduled_date,
        start_date: getDates().start_date,
        processed_date: getDates().processed_date,
        bank_account_id: getBankAccountId(),
        invoices: calculateInvoiceAmounts(),
        installment: undefined,
      };
      dispatch<any>(postPaymentAction(payload, close));
      return true;
    }
    // OFFLINE
    const payload = {
      ...paymentState,
      payment_info_id: undefined,
      paid_date: getDates().paid_date,
      scheduled_date: getDates().scheduled_date,
      start_date: getDates().start_date,
      processed_date: getDates().processed_date,
      bank_account_id: getBankAccountId(),
      invoices: calculateInvoiceAmounts(),
      installment: undefined,
    };
    dispatch<any>(postPaymentAction(payload, close));
    return true;
  };

  return (
    <>
      <div className={classes.header}>
        <div className={`${classes.row} ${classes.noMarginHeight}`}>
          <div className={classes.title}>{t(lang, 'forms.invoices.make_payment')}</div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
        <div className={`${classes.row} ${classes.noMarginHeight}`}>
          <Tooltip
            title={t(lang, 'menus.make_payment')}
            placement="top-start"
          >
            <span>
              <ActionButton
                category={MENU_BUTTON_CATEGORY.action}
                onClick={handleSubmit}
              >
                {displaySaveIcon()}
              </ActionButton>
            </span>
          </Tooltip>
        </div>
      </div>
      <form className={classes.scroller} noValidate>
        <Subtitle noMargin>Title</Subtitle>
        <div className={classes.row}>
          <FormTextField
            form={FORM.make_payment}
            label={t(lang, 'forms.invoices.title')}
            name="title"
            onChange={onFieldChange}
            required
            value={paymentState.title}
          />
        </div>
        <Subtitle noMargin style={{ marginBottom: '24px' }}>Contacts</Subtitle>
        <div className={classes.row}>
          <ContactSelector
            autocompleteId={AUTOCOMPLETE_ID.add_payment_contact}
            onChange={(id: number) => onFieldChange(id, INVOICE_FIELDS.contact_id)}
            label={t(lang, 'forms.payments.payer')}
            form={FORM.make_payment}
            name={INVOICE_FIELDS.contact_id}
            contactId={paymentState.contact_id}
          />
        </div>
        <div className={classes.row}>
          <ContactSelector
            autocompleteId={AUTOCOMPLETE_ID.add_payment_contact}
            onChange={(id: number) => onFieldChange(id, INVOICE_FIELDS.seller_id)}
            label={t(lang, 'forms.payments.seller')}
            form={FORM.make_payment}
            name={INVOICE_FIELDS.seller_id}
            contactId={paymentState.seller_id}
          />
        </div>
        <Subtitle noMargin style={{ marginBottom: '16px' }}>Amounts</Subtitle>
        <div className={classes.row} style={{ marginBottom: '0px' }}>
          <b>Balance:</b>{ceil(getInvoicesBalance(), 2)}
        </div>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <div className={classes.col2}>
            <FormTextField
              form={FORM.make_payment}
              label={t(lang, 'forms.payments.amount')}
              name="amount"
              onChange={onAmountChange}
              required
              value={paymentState.amount ?? '0.00'}
            />
          </div>
        </div>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <FormControl
            component="fieldset"
            style={{ marginTop: '8px' }}
          >
            <FormCheckbox
              name="issue_tax_receipt"
              label={t(lang, 'forms.payments.issue_tax_receipt')}
              checked={!!paymentState.issue_tax_receipt}
              onChange={onFieldChange}
            />
          </FormControl>
        </div>
        <Subtitle noMargin style={{ marginBottom: '16px' }}>Payment type</Subtitle>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <FormControl
            component="fieldset"
          >
            <RadioGroup
              row
              aria-label={t(lang, 'forms.payments.payment_type')}
              name="payment_type"
              value={paymentState.payment_type}
              onChange={
                (e) => onPaymentTypeChange(e.target.value as PAYMENT_TYPE)
              }
            >
              {getPaymentTypesLabels()}
            </RadioGroup>
          </FormControl>
        </div>
        {isOnlinePayment() && (
          <div className={`${classes.row} ${classes.noMargin}`}>
            <div className={classes.col2}>
              <PaymentAccountSelector
                value={paymentState.payment_info_id || undefined}
                type={paymentState.payment_type}
                onChange={(id: number) => onFieldChange(id, 'payment_info_id')}
                form={FORM.make_payment}
                name="payment_info_id"
                contactId={paymentState.contact_id as number ?? 1}
              />
            </div>
            <div className={classes.col2}>
              {displayPaymentInfoSelector()}
            </div>
          </div>
        )}
        <div
          className={classes.row}
          style={{
            marginBottom: '0px',
            alignItems: 'start',
          }}
        >
          <div className={classes.col2}>
            <OrganisationBankAccountSelector
              style={{ minWidth: '100%' }}
              value={paymentState.bank_account_id || undefined}
              onChange={(id: number) => onFieldChange(id, 'bank_account_id')}
              form={FORM.make_payment}
              name="bank_account_id"
              disabled={processing || isOnlinePayment()}
            />
          </div>
          <div className={classes.col2}>
            {displayGatewaySelector()}
          </div>
        </div>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <div className={classes.col2}>
            {displayPaymentStatusSelector()}
          </div>
          <div>
            {displayDate()}
          </div>
        </div>
        { displayProcessedDate() }
        <div className={`${classes.row} ${classes.noMargin}`}>
          <FormControl
            component="fieldset"
          >
            <FormCheckbox
              name="is_installment"
              label={t(lang, 'forms.payments.is_installment')}
              checked={!!paymentState.is_installment}
              onChange={onFieldChange}
              disabled={processing}
            />
          </FormControl>
        </div>
        {!!paymentState.is_installment && (
        <>
          <div className={`${classes.row} ${classes.noMargin}`}>
            <div className={classes.col2}>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.payments.number')}
                name="number"
                onChange={onInstallmentChange}
                required
                disabled={processing}
                value={installmentState.number || ''}
              />
            </div>
            <div className={classes.col2}>
              <FormControl className={classes.selectDate}>
                <InputLabel id="frequency">Frequency</InputLabel>
                <Select
                  labelId="frequency-label-id-"
                  id="demo-simple-select"
                  value={installmentState.frequency || ''}
                  onChange={(e) => onInstallmentChange(e.target.value as string, 'frequency')}
                  disabled={processing}
                >
                  {displayInstallmentFrequencyOptions()}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.row}>
            {displayInstallmentDetails()}
          </div>
        </>
        )}
        <Subtitle
          noMargin
          style={{
            marginBottom: '8px',
            marginTop: '8px',
          }}
        >Meta Data
        </Subtitle>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <div className={classes.col2}>
            <MetadataSelector
              type={MetadataType.program}
              onChange={(id) => onFieldChange(id, 'program_id')}
              form={FORM.make_payment}
              name="program_id"
              value={paymentState.program_id || undefined}
              noMarginTop
            />
          </div>
          <div className={classes.col2}>
            <MetadataSelector
              type={MetadataType.account}
              onChange={(id) => onFieldChange(id, 'account_id')}
              form={FORM.make_payment}
              name="account_id"
              value={paymentState.account_id || undefined}
              noMarginTop
            />
          </div>
        </div>

        <div className={`${classes.row} ${classes.noMargin}`}>
          <div className={classes.col2}>
            <MetadataSelector
              type={MetadataType.department}
              onChange={(id) => onFieldChange(id, 'department_id')}
              form={FORM.make_payment}
              name="department_id"
              value={paymentState.department_id || undefined}
              noMarginTop
            />
          </div>
        </div>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <FormTextField
            form={FORM.make_payment}
            label="Notes"
            multiline
            minRows="4"
            name="description"
            onChange={onFieldChange}
            value={paymentState.description || ''}
          />
        </div>
      </form>
    </>
  );
};

export default MakePaymentForm;
