import PaymentModel from 'src/models/PaymentModel';
import { PaymentSections } from 'src/components/Pages/UserPaymentsPage';
import { Moment } from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { SEARCH_OPERATORS, SEARCH_OPERATORS_PRIMITIVE } from 'src/constants/SearchOperators';
import { Filter, Filters, SEARCH_LOGICAL_OPERATORS } from 'src/models/AdvanceSearchModel';
import { ReceiptModel } from 'src/models/ReceiptModel';
import { INVOICE_STATUS } from 'src/constants/Invoices';

export const getCardType = (number: string) => {
  const typeList = {
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
    amex: /^3[47][0-9]{13}$/,
    diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  };
  // eslint-disable-next-line
  for (const key in typeList) {
    if (typeList[key].test(number)) {
      return key;
    }
  }
  return 'unknown';
};

export const normalizeReceiptForUser = (receipt: any) => {
  const result = {} as ReceiptModel;
  Object.keys(receipt).forEach((key: string) => {
    const [entity, field] = key.split('.');
    if (!field) {
      result[entity] = receipt[key];
    } else {
      result[field] = receipt[key];
    }
  });
  return result;
};

export const createInvoiceSearchFilter = (term: string) => {
  const filters = { [SEARCH_LOGICAL_OPERATORS.or]: [] as Filter[] };
  term.split(',').forEach((subquery: string) => {
    const trimmed = subquery.trim();
    filters.or.push(['invoices.title', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.or.push(['invoices.sequence', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.or.push(['invoices.contact_first_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.or.push(['invoices.contact_last_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.or.push(['invoices.contact_company_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
  });
  return filters;
};

export const createInvoiceSearchTermsFilter = (term: string) => {
  const results = [] as Filters[];
  term.split(',').forEach((subquery: string) => {
    const trimmed = subquery.trim();
    const filters = [] as Filter[];
    filters.push(['invoices.title', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.push(['invoices.sequence', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.push(['invoices.contact_first_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.push(['invoices.contact_last_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.push(['invoices.contact_company_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    results.push({ [SEARCH_LOGICAL_OPERATORS.or]: filters });
  });
  return results;
};

export const createPaymentSearchFilter = (term: string) => {
  const filters = { [SEARCH_LOGICAL_OPERATORS.or]: [] as Filter[] };
  term.split(',').forEach((subquery: string) => {
    const trimmed = subquery.trim();
    filters.or.push(['payments.title', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.or.push(['payments.contact_first_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.or.push(['payments.contact_last_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.or.push(['payments.contact_company_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.or.push(['payments.id', SEARCH_OPERATORS.like, `%${trimmed}%`]);
  });
  return filters;
};

export const createPaymentSearchTermsFilter = (term: string) => {
  const results = [] as Filters[];
  term.split(',').forEach((subquery: string) => {
    const trimmed = subquery.trim();
    const filters = [] as Filter[];
    filters.push(['payments.title', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.push(['payments.contact_first_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.push(['payments.contact_last_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.push(['payments.contact_company_name', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    filters.push(['payments.id', SEARCH_OPERATORS.like, `%${trimmed}%`]);
    results.push({ [SEARCH_LOGICAL_OPERATORS.or]: filters });
  });
  return results;
};

export const getReceiptRangeDate = () => {
  const start = moment().subtract(1, 'year').startOf('day');
  const end = moment().endOf('day');
  return [start, end];
};

export const getReceiptFilterAll = (
  start: Moment,
  end: Moment,
  terms?: string,
) => {
  const startString = start.startOf('day');
  const endString = end.endOf('day');
  const filters = [
    ['tax_receipts.issued_date', SEARCH_OPERATORS.is_between, [startString, endString]],
    ['tax_receipts.voided', SEARCH_OPERATORS_PRIMITIVE.not_null, ''],
  ] as (Filter | Filters)[];
  if (terms) {
    terms.split(',').forEach((term) => {
      filters.push(createPaymentSearchFilter(term));
    });
  }
  return filters;
};

export const getReceiptFilterPurchase = (
  start: Moment,
  end: Moment,
  terms?: string,
) => {
  const startString = start.startOf('day');
  const endString = end.endOf('day');
  const filters = [
    ['tax_receipts.issued_date', SEARCH_OPERATORS.is_between, [startString, endString]],
    ['tax_receipts.issued_date', SEARCH_OPERATORS.is_between, [startString, endString]],
    ['tax_receipts.voided', SEARCH_OPERATORS_PRIMITIVE.not_null, ''],
    ['tax_receipts.sequence', SEARCH_OPERATORS_PRIMITIVE.null, ''],
  ] as (Filter | Filters)[];
  if (terms) {
    terms.split(',').forEach((term) => {
      filters.push(createPaymentSearchFilter(term));
    });
  }
  return filters;
};

export const getReceiptFilterTax = (
  start: Moment,
  end: Moment,
  terms?: string,
) => {
  const startString = start.startOf('day');
  const endString = end.endOf('day');
  const filters = [
    ['tax_receipts.issued_date', SEARCH_OPERATORS.is_between, [startString, endString]],
    ['tax_receipts.issued_date', SEARCH_OPERATORS.is_between, [startString, endString]],
    ['tax_receipts.voided', SEARCH_OPERATORS_PRIMITIVE.not_null, ''],
    ['tax_receipts.sequence', SEARCH_OPERATORS_PRIMITIVE.not_null, ''],
  ] as (Filter | Filters)[];
  if (terms) {
    terms.split(',').forEach((term) => {
      filters.push(createPaymentSearchFilter(term));
    });
  }
  return filters;
};

export const getReceiptFilter = (
  startDate: Moment,
  endDate: Moment,
) => {
  const startString = startDate.startOf('day');
  const endString = endDate.endOf('day');
  const filters = [
    ['tax_receipts.issued_date', SEARCH_OPERATORS.is_between, [startString, endString]],
  ] as (Filter | Filters)[];
  return filters;
};

export const normalizePaymentForUser = (payment: any) => {
  const result = {} as PaymentModel;
  Object.keys(payment).forEach((key: string) => {
    const [entity, field] = key.split('.');

    if (!field) {
      result[entity] = payment[key];
    } else {
      result[field] = payment[key];
    }
  });
  return result;
};

export const getPaymentSearchDateAll = (start: Moment, end: Moment) => {
  if (end < start) {
    const startDay = end.startOf('day');
    const endDay = start.endOf('day');
    return [startDay, endDay];
  }
  const startDay = start.startOf('day');
  const endDay = end.endOf('day');
  return [startDay, endDay];
};

export const getPaymentSearchDateScheduled = (start: Moment, end: Moment) => {
  if (end < start) {
    const startDay = end.startOf('day');
    const endDay = start.endOf('day');
    return [startDay, endDay];
  }
  const startDay = start.startOf('day');
  const endDay = end.endOf('day');
  return [startDay, endDay];
};

export const getPaymentSearchDateError = (start: Moment, end: Moment) => {
  if (end < start) {
    const startDay = end.startOf('day');
    const endDay = start.endOf('day');
    return [startDay, endDay];
  }
  const startDay = start.startOf('day');
  const endDay = end.endOf('day');
  return [startDay, endDay];
};

export const getPaymentSearchDate = (start: Moment, end: Moment, section: PaymentSections) => {
  switch (section) {
    case PaymentSections.error:
      return getPaymentSearchDateError(start, end);
    case PaymentSections.scheduled:
      return getPaymentSearchDateScheduled(start, end);
    default:
      return getPaymentSearchDateScheduled(start, end);
  }
};

export const getPaymentRangeDateAll = () => {
  const start = moment().subtract(1, 'year').startOf('day');
  const end = moment().add(2, 'year').endOf('day');
  return [start, end];
};

export const getPaymentRangeDateScheduled = () => {
  const start = moment().startOf('day');
  const end = moment().add(1, 'year').endOf('day');
  return [start, end];
};

export const getPaymentRangeDateError = () => {
  const start = moment().subtract(1, 'year').endOf('day');
  const end = moment().endOf('day');
  return [start, end];
};

export const getPaymentRangeDate = (section: PaymentSections) => {
  switch (section) {
    case PaymentSections.error:
      return getPaymentRangeDateError();
    case PaymentSections.scheduled:
      return getPaymentRangeDateScheduled();
    default:
      return getPaymentRangeDateAll();
  }
};

const getPaymentFilterAll = (
  start: Moment,
  end: Moment,
  terms?: string,
) => {
  const startString = start.startOf('day');
  const endString = end.endOf('day');
  const filters = [
    {
      or: [
        ['payments.processed_date', SEARCH_OPERATORS.is_between, [startString, endString]],
        ['payments.scheduled_date', SEARCH_OPERATORS.is_between, [startString, endString]],
      ],
    },
  ] as (Filter | Filters)[];
  if (terms) {
    terms.split(',').forEach((term) => {
      filters.push(createPaymentSearchFilter(term));
    });
  }
  return filters;
};

export const getPaymentFilter = (
  startDate: Moment,
  endDate: Moment,
  terms?: string,
) => getPaymentFilterAll(startDate, endDate, terms);

export const getInvoiceFilter = (
  startDate: Moment,
  endDate: Moment,
  terms?: string,
) => {
  const filters = [
    [
      'invoices.issued_date',
      SEARCH_OPERATORS.is_between,
      [
        startDate.startOf('day'),
        endDate.endOf('day'),
      ],
    ],
    [
      'invoices.state',
      SEARCH_OPERATORS_PRIMITIVE.in,
      [INVOICE_STATUS.final, INVOICE_STATUS.legacy, INVOICE_STATUS.error],
    ],
  ] as (Filter | Filters)[];
  if (terms) {
    terms.split(',').forEach((term) => {
      filters.push(createInvoiceSearchFilter(term));
    });
  }
  return filters;
};
