import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/Elements/MenuButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import { TextField, Tooltip } from '@material-ui/core';
import { DepartmentItemModel } from 'src/models/DepartmentListModel';
import EditDepartmentForm from 'src/components/Forms/EditDepartmentForm';
import AddDepartmentForm from 'src/components/Forms/AddDepartmentForm';
import { getDepartmentsAction } from 'src/redux/actions/dataActions';
import { sortBy } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '56px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '597px',
    padding: '8px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
    padding: '0px 8px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  search: {
    height: '6vh',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
}));

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageDepartments = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDepartmentsAction());
  }, []);

  const departments = useSelector((state: Store) => state.data.departments);
  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageDepartment }));
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentDepartment, setCurrentDepartment] = useState({} as DepartmentItemModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(Object.values(departments).slice(0, 20));
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setItems(Object.values(departments).slice(0, 20));
  }, [departments]);

  const handleDelete = (e:any, group: DepartmentItemModel) => {
    e.preventDefault();
    dispatch(openDialog({ dialog: DIALOGS.deleteDepartment, payload: group }));
  };

  const handleEdit = (department: DepartmentItemModel) => {
    setCurrentDepartment(department);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentDepartment({} as DepartmentItemModel);
    setCurrentState(STATE.add);
  };

  const handleNext = () => {
    const sorted = sortBy(departments, (department: DepartmentItemModel) => department[`name_${lang}`]);
    let result = sorted;
    if (currentFilter) {
      result = sorted.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(currentFilter.toLowerCase()),
      );
    }
    const newItems = result.slice(page * 20, (page + 1) * 20);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(result.length > (page + 1) * 20);
  };

  const handleSearch = (term: string) => {
    const sorted = sortBy(departments, (department) => department[`name_${lang}`]);
    let result = sorted;
    if (term) {
      result = result.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(term.toLowerCase()),
      );
    }
    setItems(result.slice(0, 20));
    setPage(1);
    setHasMore(result.length > 40);
    setCurrentFilter(term);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_department');
      case STATE.edit:
        return t(lang, 'menus.edit_department');
      default:
        return t(lang, 'menus.list_department');
    }
  };

  const getStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const getItems = () => items.map(
    (department: DepartmentItemModel, index: number) => (
      <div
        className={classes.item}
        key={`Department-${index}`}
        style={getStyle(index)}
      >
        <span><b>{ department[`name_${lang}`] }</b></span>
        <Tooltip
          title={t(lang, 'menus.edit_Department')}
          placement="top-start"
        >
          <span style={{ marginLeft: 'auto' }}>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={() => handleEdit(department)}
            >
              <EditIcon />
            </ActionButton>
          </span>
        </Tooltip>
        <Tooltip
          title={t(lang, 'menus.delete_Department')}
          placement="top-start"
        >
          <span style={{ marginLeft: '8px' }}>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={(e) => handleDelete(e, department)}
            >
              <DeleteIcon />
            </ActionButton>
          </span>
        </Tooltip>
      </div>
    ),
  );

  const displayItems = () => (
    <InfiniteScroll
      dataLength={items.length}
      next={handleNext}
      hasMore={hasMore}
      loader={<Typography>Loading...</Typography>}
      endMessage={<Typography>All items displayed.</Typography>}
      height={590}
    >
      {getItems()}
    </InfiniteScroll>
  );

  const displayEditForm = () => (
    <div className={classes.scroller}>
      <EditDepartmentForm
        department={currentDepartment}
        onClose={() => setCurrentState(STATE.list)}
      />
    </div>
  );

  const displayAddForm = () => (
    <div className={classes.scroller}>
      <AddDepartmentForm
        onClose={() => setCurrentState(STATE.list)}
      />
    </div>
  );

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}><b>{getTitle()}</b></div>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
          >
            {t(lang, 'misc.close')}
          </Button>
        </div>
      </div>
      {currentState === STATE.list && (
      <div className={classes.search}>
        <Tooltip
          title={t(lang, 'menus.add_customfield')}
          placement="top-start"
        >
          <span>
            <ActionButton
              category={MENU_BUTTON_CATEGORY.action}
              onClick={handleAdd}
            >
              <AddIcon />
            </ActionButton>
          </span>
        </Tooltip>
        <TextField
          margin="dense"
          variant="outlined"
          label="Filter"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      )}
      <div>
        {currentState === STATE.list && displayItems()}
        {currentState === STATE.add && displayAddForm()}
        {currentState === STATE.edit && displayEditForm()}
      </div>
    </>
  );
};

export default ManageDepartments;
