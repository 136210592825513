import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ENTITIES, ORDER, QuerbuilderPayloadColumns } from 'src/models/QuerybuilderModel';
import Datagrid from 'src/components/Datagrid/Datagrid';
import { useEffect, useState } from 'react';
import {
  getEntitiesAction,
  setColumns,
  setFilters,
  setOrders,
  setQueryBuilderResult,
} from 'src/redux/actions/querybuilderActions';
import DEFAULT_COLUMNS from 'src/constants/columns';
import { resetSelections } from 'src/redux/actions/selectionActions';
import { InvoiceModel } from 'src/models/InvoiceModel';
import { t } from 'src/lib/language';
import { getPaymentAction } from 'src/redux/actions/paymentActions';
import InvoiceForPaymentToolbar
  from 'src/components/DatagridToolbar/entities/InvoiceForPaymentToolbar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    fontSize: '1.8rem',
    fontFamily: 'Qanelas-Bold',
    height: '48px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  noMargin: {
    height: '32px',
    marginBottom: '0 !important',
  },
}));

const InvoicesForPaymentDatagrid = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const lang = useSelector((state: Store) => state.language.language);

  const getPaymentId = () => {
    const pathSegments = window.location.pathname.split('/');
    return Number(pathSegments[pathSegments.length - 1]);
  };

  const payment = useSelector((state: Store) => state.payments[getPaymentId()]);
  const [invoiceIds, setInvoiceIds] = useState([] as number[]);

  useEffect(() => {
    dispatch(getPaymentAction(getPaymentId()));
  }, []);

  useEffect(() => {
    if (!invoiceIds.length) return;
    dispatch(setColumns({
      entity: ENTITIES.invoices,
      columns: DEFAULT_COLUMNS.invoicesForPayment,
    } as QuerbuilderPayloadColumns));
    dispatch(setOrders({
      entity: ENTITIES.invoices,
      order: [['invoices.id', ORDER.desc]],
    }));
    dispatch(setFilters({
      entity: ENTITIES.invoices,
      filters: {
        and: [['invoices.id', 'IN', invoiceIds]],
      },
    }));
    dispatch(resetSelections({ entity: ENTITIES.invoices }));
    dispatch(setQueryBuilderResult({
      entity: ENTITIES.invoices,
      result: {
        count: 0,
        list: [],
      },
    }));
    dispatch(getEntitiesAction(ENTITIES.invoices));
  }, [invoiceIds]);

  const getTitle = () => {
    if (!payment) return '';
    if (!payment.title) return `#${payment.id}`;
    return `${payment.title} (#${payment.id})`;
  };

  useEffect(() => {
    if (!payment) return;
    document.title = `${t(lang, 'forms.contact.invoices_for')}: ${getTitle()}`;
    setInvoiceIds(
      payment.invoices.map((invoices: InvoiceModel) => invoices.id),
    );
  }, [payment]);

  return (
    <>
      <div className={classes.header}>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <Typography
            variant="h5"
            color="secondary"
            gutterBottom
            className={classes.header}
          >
            {t(lang, 'forms.contact.invoices_for')} {getTitle()}
          </Typography>
        </div>
      </div>
      <InvoiceForPaymentToolbar />
      <Datagrid entity={ENTITIES.invoices} dontReloadEntities />
    </>
  );
};

export default InvoicesForPaymentDatagrid;
